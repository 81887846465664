@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.NavigationRail {
  margin-top: 30px !important;
}
.NavigationRail a {
  gap: 6px;
}

.Widget .Widget--Header h6 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.Widget .Widget--Header a {
  color: #73589b;
  text-align: right;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.NotificationTabs {
  border: none;
  gap: 10px;
  padding: 10px 20px;
}
.NotificationTabs .nav-link {
  transition: none;
}
.NotificationTabs .nav-link:hover {
  border: none;
}
.NotificationTabs .nav-link {
  color: #153300 !important;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}
.NotificationTabs .nav-item .nav-link.active {
  background-color: #153300;
  color: #fff !important;
}

.NotificationPanel {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.NotificationPanel > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #d9d9d9;
}

.NotificationsWidget .tab-content > .active {
  padding-bottom: 30px;
}

.ExpertDashboard {
  max-height: calc(100vh - 75px);
  height: calc(100vh - 75px);
  overflow: hidden;
}
.ExpertDashboard .ExpertDashboard--Col {
  min-height: calc(100vh - 61px);
  max-height: calc(100vh - 61px);
  height: 100%;
  padding-bottom: 30px;
  overflow-y: auto;
}
.ExpertDashboard .ExpertDashboard--Col::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ExpertDashboard .ExpertDashboard--Col::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

.CalendarWidget .Widget--Header .MuiOutlinedInput-input {
  padding: 9px 12px 9px 8px;
  border-radius: 10px !important;
  background: #e7f6ff;
}
.CalendarWidget .Widget--Header .MuiOutlinedInput-notchedOutline {
  border-width: 0;
}

.Days--Container {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.Content--Days {
  padding: 10px 20px;
}
.Content--Days .Day--Item {
  padding: 0.625rem 0.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Content--Days .Day--Item span {
  text-align: center;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #73589b;
}
.Content--Days .Day--Item p {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Content--Days .Day--Item .hasAppointment {
  padding: 2px;
  background-color: red;
  border-radius: 50%;
}
.Content--Days .SelectedDay {
  background-color: #f4edff;
  border-radius: 10px;
  border: 1px solid #73589b;
}
.Content--Days .SelectedDay .span {
  color: #73589b;
}

.Content--Data {
  display: grid;
  padding: 10px 20px;
  gap: 5px;
}
.Content--Data .Data--Item {
  border-radius: 0.625rem;
  border: 1px solid #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: start;
  gap: 10px;
}
.Content--Data .Item--details {
  display: flex;
  flex-direction: column;
}
.Content--Data .Item--details > p {
  color: #000;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Content--Data .Item--details > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.Content--Data .Item--details > div p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header {
  flex-direction: column;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header > div {
  justify-content: space-between;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header > div .ViewCalendar {
  background: #73589b;
  display: flex;
  gap: 5px;
  box-shadow: none;
  padding: 8px 24px;
  border-radius: 10px;
  font-size: 14px;
  text-transform: capitalize;
  font-size: 14px;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Selectors {
  align-items: center;
  margin-left: auto;
}
.ExpertAppointmentDashboard .ExpertAppointmentDashboard--Header .Selectors p {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}
.ExpertAppointmentDashboard .MuiSelect-select {
  min-width: 70px;
  min-height: 0;
  padding: 13.5px;
}
.ExpertAppointmentDashboard .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.ExpertAppointmentDashboard .AppointmentCalendarView {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: -16px;
}

.CalendarView--Content .rbc-header {
  min-height: 42px;
  border: 0 !important;
}
.CalendarView--Content .rbc-events-container {
  overflow: hidden;
  margin-right: 0 !important;
}
.CalendarView--Content .rbc-event {
  background-color: #FFF !important;
  color: #3174ad !important;
  height: -moz-max-content !important;
  height: max-content !important;
}
.CalendarView--Content .rbc-day-slot .rbc-event-label {
  display: none;
}
.CalendarView--Content .rbc-toolbar button.rbc-active:hover, .CalendarView--Content .rbc-toolbar button.rbc-active:focus {
  background-color: #73589b !important;
  border-color: #73589b !important;
  color: #FFF !important;
}
.CalendarView--Content .rbc-toolbar button.rbc-active {
  background-color: #73589b !important;
  border-color: #73589b !important;
  color: #FFF !important;
}
.CalendarView--Content .rbc-month-view {
  border: 0;
}
.CalendarView--Content .rbc-month-view .rbc-month-header {
  border-bottom: 1px solid #dddddd;
}

.AppointmentCalendarView--Component {
  padding: 22px 10px 40px 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 20px;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header .MuiFormControl-root {
  width: 128px;
  border-radius: 15px;
  background-color: #e7f6ff;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCalendarView--Component .AppointmentCalendarView--Header span {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.AppointmentCalendarView--Component .Calendar--Section {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 10px;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 6px;
  margin: 0 auto;
  border-radius: 10px;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .day {
  color: #21231e;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .date {
  color: #21231e;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AppointmentCalendarView--Component .Calendar--Section .date-button .indicator {
  padding: 2px;
  border-radius: 50%;
  background-color: #000;
}
.AppointmentCalendarView--Component .Calendar--Section .isActive {
  border: 1px solid #73589b;
  background-color: #e7f6ff;
}
.AppointmentCalendarView--Component .Calendar--Section .inactive .day,
.AppointmentCalendarView--Component .Calendar--Section .inactive .date {
  color: #929292;
}
.AppointmentCalendarView--Component .Calendar--Section .inactive .indicator {
  background-color: #929292;
}
.AppointmentCalendarView--Component .Appointment--Item {
  background-color: #f5f5f5;
}
.AppointmentCalendarView--Component .Completed--Item {
  background-color: #fff;
}

.Appointments--Container {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}
.Appointments--Container .Appointment--Item {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  gap: 10px;
}
.Appointments--Container .Appointment--Item .Item--Details {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.Appointments--Container .Appointment--Item .Item--Details h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
}
.Appointments--Container .Appointment--Item .Item--Details h6 svg {
  color: #d2293d;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
}
.Appointments--Container .Appointment--Item .Item--Details span {
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Appointments--Container .Appointment--Item .Item--Details p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Appointments--Container .Appointment--Item .CTA--Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.Appointments--Container .Appointment--Item .CTA--Container button {
  background: #f5f5f5;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
}
.Appointments--Container .Appointment--Item .CTA--Container button svg {
  width: 16px;
  height: 16px;
}
.Appointments--Container .Appointment--Item .CTA--Container button p {
  line-height: 130%;
  text-transform: capitalize;
}
.Appointments--Container .Appointment--Item .CTA--Container button:last-child {
  padding: 8px;
  min-width: 0;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.Appointments--Container .Appointment--Item .CTA--Container button:last-child svg {
  width: 20px;
  height: 20px;
}

.ClientDashboard {
  overflow-x: hidden;
}
.ClientDashboard .ClientDashboard--Header {
  flex-direction: column;
}
.ClientDashboard .ClientDashboard--Header > div {
  justify-content: space-between;
}
.ClientDashboard .ClientDashboard--Header > div .AddAppointment {
  background: #73589b;
  display: flex;
  gap: 5px;
  box-shadow: none;
  padding: 8px 24px;
  border-radius: 10px;
  font-size: 14px;
  text-transform: capitalize;
  font-size: 14px;
}
.ClientDashboard .ClientDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ClientDashboard .ClientDashboard--Header .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ClientDashboard .ClientDashboard--Header .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}
.ClientDashboard .TableHeader p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.AddApointment--Dialog {
  max-width: "610px";
  width: "98%";
  min-width: "580px";
}
.AddApointment--Dialog .Dialog--Content .SlotTiming {
  margin-top: 20px;
  align-items: center;
}
.AddApointment--Dialog .Dialog--Content .SlotTiming p {
  color: #929292;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.AddApointment--Dialog .Dialog--Content .SlotTiming .SelectedSlot {
  font-weight: 600;
  color: #73589b;
}
.AddApointment--Dialog .Dialog--Content .Timings {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .Timings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.AddApointment--Dialog .Dialog--Content .Timings .MuiGrid-item p {
  color: #929292;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.AddApointment--Dialog .Dialog--Content .Timings .AvailableSlot {
  background-color: #fff;
}
.AddApointment--Dialog .Dialog--Content .Timings .AvailableSlot p {
  color: #000;
}
.AddApointment--Dialog .Dialog--Content .Timings .SelectedSlot {
  border: 1px solid #73589b;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(115, 88, 155, 0.2) !important;
}
.AddApointment--Dialog .Dialog--Content .Timings .SelectedSlot p {
  color: #73589b;
  font-weight: 600;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container h6 {
  color: #21231e;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings {
  margin-top: 20px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #fff;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .MuiGrid-item p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .SelectedSTiming {
  border: 1px solid #73589b;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(115, 88, 155, 0.2) !important;
}
.AddApointment--Dialog .Dialog--Content .SessionDuration--Container .SessionTimings .SelectedSTiming p {
  color: #73589b;
  font-weight: 600;
}
.AddApointment--Dialog .ConfirmBooking {
  display: inline-flex;
  height: 50px;
  padding: 14px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #73589b;
  margin-bottom: 22px;
}

@media screen and (max-width: 1024px) {
  .AddApointment--Dialog {
    min-width: "95vw";
    margin: 0;
  }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

.ClientSelect--MenuItem {
  align-items: center;
}
.ClientSelect--MenuItem img {
  aspect-ratio: 1/1;
}
.ClientSelect--MenuItem h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.ClientSelect--MenuItem p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ClientSelect--Form label {
  background-color: #fff;
  padding-right: 4px;
}

.ClientDetails--Tabs .MuiTabs-flexContainer {
  border-bottom: 1px solid #d9d9d9;
}
.ClientDetails--Tabs .MuiTabs-flexContainer button {
  text-transform: capitalize;
  padding: 12px 10px !important;
  font-weight: 600 !important;
  color: #73589b;
}
.ClientDetails--Tabs .css-1aquho2-MuiTabs-indicator {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 4px !important;
  background-color: #73589b;
}

.Tab--Panel {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Tab--Panel .Appointment--Item {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}
.Tab--Panel .Appointment--Item .AppointmentStatus {
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .Appointment--Item .Upcoming,
.Tab--Panel .Appointment--Item .Open {
  color: #73589b;
  background-color: rgba(80, 179, 239, 0.1019607843);
}
.Tab--Panel .Appointment--Item .Completed {
  background-color: rgba(85, 165, 29, 0.1019607843);
  color: #55a51d;
}
.Tab--Panel .Appointment--Item .Cancelled {
  background-color: rgba(210, 41, 61, 0.1019607843);
  color: #d2293d;
}
.Tab--Panel .Appointment--Item .Appointment--Header {
  padding: 10px;
}
.Tab--Panel .Appointment--Item .Appointment--Header p {
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes {
  padding: 10px;
  border-top: 1px solid #d9d9d9;
  background: #f5f5f5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}
.Tab--Panel .Appointment--Item .AppointmentNotes p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Tab--Panel .WorksheetItem {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.Tab--Panel .WorksheetItem h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .WorksheetItem span {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.Tab--Panel .NotesItem {
  padding: 10px;
}
.Tab--Panel .NotesItem p {
  color: #656565;
}
.Tab--Panel .PrescriptionItem p {
  color: #21231e;
}
.Tab--Panel .PrescriptionItem span {
  color: #656565;
  font-size: 14px;
}
.Tab--Panel .NotesItem h6,
.Tab--Panel .PrescriptionItem h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0 !important;
}
.Tab--Panel .NotesItem p,
.Tab--Panel .PrescriptionItem p {
  margin-top: 10px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  word-break: break-all;
}
.Tab--Panel .Screener--Item {
  padding: 19px 25px;
  background-color: #f5f5f5;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
}
.Tab--Panel .Screener--Item h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .Screener--Item p {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.Tab--Panel .Screener--Item .CTA--Container button {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  display: flex;
  height: 38px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 32px;
  background: rgba(115, 88, 155, 0.1019607843);
  text-transform: capitalize;
}
.Tab--Panel .Course--Item {
  display: flex;
  align-items: center;
  gap: 16px;
}
.Tab--Panel .Course--Item img {
  border-radius: 5px;
  -o-object-fit: cover;
     object-fit: cover;
}
.Tab--Panel .Course--Item h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Tab--Panel .Course--Item span {
  display: inline-flex;
  height: 25px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Tab--Panel .Course--Item .ongoing {
  border-radius: 32px;
  background: rgba(208, 208, 208, 0.19);
  color: #73589b;
}
.Tab--Panel .Course--Item .completed {
  border-radius: 32px;
  background: rgba(85, 165, 29, 0.2);
  color: #153300;
}

.Prescriptions--Panel > * + *,
.Courses--Panel > * + * {
  border-top-width: 2px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #d9d9d9;
  padding-top: 16px;
}

.ClientDetailsGrid--Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.ClientDetailsGrid--Right img {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ClientDetailsGrid--Right h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ClientDetailsGrid--Right span {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.ClientDetailsGrid--Right .CTA--Container,
.ClientDetailsGrid--Right .ClientActions--Container {
  width: 100%;
}
.ClientDetailsGrid--Right .ClientActions--Container {
  margin: 20px 0;
}
.ClientDetailsGrid--Right .ClientActions--Container button {
  display: inline-flex;
  height: 38px;
  padding: 7px 13px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 32px;
  background: #f5f5f5;
  flex: 1;
  color: #414141;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: capitalize;
}

.Screener--Details {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.Screener--Details .Screener--Header {
  text-align: center;
}
.Screener--Details .Screener--Header h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.Screener--Details .Screener--Header span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Screener--Details .Screener--Header span b {
  color: #000;
}
.Screener--Details .Screener--ClientDetails {
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid var(--primary-color-opacity30);
  background: var(--primary-accent-color);
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 18px;
}
.Screener--Details .Screener--ClientDetails img {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Screener--Details .Screener--ClientDetails div {
  display: flex;
  flex-direction: column;
}
.Screener--Details .Screener--ClientDetails div h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Screener--Details .Screener--ClientDetails div p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.Screener--Details .Screener--ClientDetails div span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.Screener--Details .Screener--ClientDetails div span b {
  color: #000;
}
.Screener--Details .Screener--Content {
  margin-top: 20px;
  width: 100%;
}
.Screener--Details .Screener--Content table th,
.Screener--Details .Screener--Content table td {
  border: 1px solid #d9d9d9;
}
.Screener--Details .Screener--Content table tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}
.Screener--Details .Screener--Content table tbody th:last-child {
  border: none !important;
}
.Screener--Details .Screener--Content .GeneralQuestionsTable tbody tr tr:last-child {
  font-weight: 600;
}

.SessionNotesView--Dialog .MuiDialog-paper {
  min-width: 800px;
  max-height: 600px;
}
@media only screen and (max-width: 767.98px) {
  .SessionNotesView--Dialog .MuiDialog-paper {
    min-width: 100%;
  }
}
.SessionNotesView--Dialog .sessionNoteView--ClientDetails > div p,
.SessionNotesView--Dialog .sessionNoteView--ClientDetails > div span {
  color: #000;
  line-height: 130%; /* 20.8px */
}
.SessionNotesView--Dialog .sessionNoteView--ClientDetails > div p {
  font-weight: 500;
}
.SessionNotesView--Dialog .sessionNoteView--Accordion.MuiPaper-root {
  border-radius: 10px !important;
}
.SessionNotesView--Dialog .sessionNoteView--Accordion.MuiPaper-root::before {
  height: 0px !important;
}

.ScheduleDashboard .ScheduleDashboard--Header {
  justify-content: space-between;
  align-items: center;
}
.ScheduleDashboard .ScheduleDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ScheduleDashboard .ScheduleDashboard--Content {
  flex-direction: column;
}

.PopulateSection--Inputs > div {
  flex: 1;
}

.PopulateSection h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.PopulateSection button {
  display: block;
  margin-left: auto;
}

.ManualSection h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ManualSection .GoToDate {
  width: 118px;
}
.ManualSection .Selector--Header h4 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ManualSection .ManualSection--Selector {
  margin-top: 20px;
}
.ManualSection .ManualSection--Selector > div {
  height: calc(100vh - 280px);
  overflow: auto;
}
.ManualSection .ManualSection--Selector > div::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
.ManualSection .ManualSection--Selector > div::-webkit-scrollbar-thumb {
  background-color: #bbb7b7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

.DateSelector--Container button {
  padding: 0 !important;
  min-width: 0;
  display: flex;
  width: 32px;
  height: 55px;
  padding: 8px 41px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f3f3f3;
  color: #73589b;
}
.DateSelector--Container th {
  padding: 0;
}
.DateSelector--Container .DateSelector--Dates {
  flex: 1;
  justify-content: space-between;
}
.DateSelector--Container .DateSelector--Item {
  height: 55px;
  display: flex;
  flex-direction: column;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.DateSelector--Container .DateSelector--Item p {
  color: #414141;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.DateSelector--Container .DateSelector--Item span {
  color: #414141;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.ManualDateSelector--Table th,
.ManualDateSelector--Table td {
  border-bottom: 0 !important;
}
.ManualDateSelector--Table thead th:first-child {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
}
.ManualDateSelector--Table tbody {
  margin-top: 15px;
}
.ManualDateSelector--Table tbody tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
.ManualDateSelector--Table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.ManualDateSelector--Table tbody tr td:first-child {
  background-color: #f3f3f3;
}
.ManualDateSelector--Table tbody tr td:first-child div {
  background: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  color: #414141;
  font-size: 14px;
}
.ManualDateSelector--Table tbody .Mui-disabled svg {
  color: rgba(0, 0, 0, 0.26) !important;
}

.redirectToAppointmentsCTA:hover {
  color: #fff;
  background-color: rgba(21, 101, 192, 0.4784313725);
}

.ExpertWorksheet--Dashboard .ExpertWorksheet--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertAppointmentDashboard--Options {
  justify-content: space-between;
}
.ExpertAppointmentDashboard--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ExpertAppointmentDashboard--Options .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}

.NewWorksheet--Grid .NewWorksheet--Left .Left--Container span {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container .css-1vgzwi7-MuiFormControl-root {
  width: 100%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container .MuiFormGroup-root {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Container .MuiFormGroup-root span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .NewSection {
  border: 2px dashed #73589b;
}
.NewWorksheet--Grid .NewWorksheet--Left .CustomQuestion {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
.NewWorksheet--Grid .NewWorksheet--Left .CustomQuestion fieldset {
  margin: 0 !important;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Data > p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Left .Left--Data > div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div > span {
  color: #d2293d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div > p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .Actions--Container button {
  flex: 1%;
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #f5f5f5;
  color: #414141;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .Actions--Container .SaveButton {
  background: #73589b;
  color: #fff;
}
.NewWorksheet--Grid .NewWorksheet--Right > div .Actions--Container .SaveButton.Mui-disabled {
  background: rgba(115, 88, 155, 0.4588235294);
}
.NewWorksheet--Grid .NewWorksheet--Right > div .dropzone > div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #929292;
  margin-top: 15px;
}

.ViewWorksheet--Table .Table--Profile h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ViewWorksheet--Table .Table--Progress p {
  border-radius: 20px;
  background: rgba(157, 234, 102, 0.2);
  padding: 5px 10px;
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: -moz-fit-content;
  width: fit-content;
}

.NewWorksheet--UserDetails .User--Details div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NewWorksheet--UserDetails .User--Details div span {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewWorksheet--UserDetails .User--Details div p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.NewWorksheet--UserDetails .User--Details div .complete {
  display: inline-flex;
  padding: 3px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: rgba(157, 234, 102, 0.2);
  color: #55a51d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ExpertArticles--Dashboard .ExpertArticles--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertArticles--Options {
  justify-content: space-between;
}
.ExpertArticles--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertArticles--Options .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}

.AddArticle--Left > div {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.AddArticle--Left p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 10px;
}
.AddArticle--Left .MuiInputBase-root {
  background-color: #fff;
}
.AddArticle--Left .rdw-editor-main {
  min-height: 380px;
  background-color: #fff;
}

.AddArticle--Right > div {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.AddArticle--Right > div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 15px;
}
.AddArticle--Right > div h6 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AddArticle--Right > div .dropzone > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #929292;
  margin-top: 15px;
}
.AddArticle--Right > div .ArticleActions--Container {
  margin-top: 10px;
}
.AddArticle--Right > div .ArticleActions--Container button {
  text-transform: capitalize;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  color: #414141;
}
.AddArticle--Right > div .ArticleActions--Container .PublishButton {
  background: #73589b;
  border-color: #73589b;
  color: #fff;
}

.ExpertPostsDashboard .ExpertPostsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options {
  margin-top: 20px;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertNotificationsDashboard .ExpertNotificationsDashboard--Header .Header--Options .SelectedItem {
  background: #73589b;
  color: #fff;
}

.ExpertNotificationsDashboard--Content {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
}
.ExpertNotificationsDashboard--Content h6 {
  padding: 20px;
}
.ExpertNotificationsDashboard--Content .Content--Item > div > * + * {
  border-top-width: 1px !important;
  border-bottom-width: 0px;
  border-color: #d9d9d9;
}
.ExpertNotificationsDashboard--Content .Content--Item .NotificationRowCard {
  border-top: 1px solid #d9d9d9 !important;
}

.ExpertHelpCenter--Dashboard .ExpertHelpCenter--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertHelpCenter--Dashboard .ExpertHelpCenter--documents {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.ExpertHelpCenter--Dashboard .ExpertHelpCenter--documents .line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-text-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ExpertHelpCenter--Options {
  justify-content: space-between;
}
.ExpertHelpCenter--Options .Options .Option--Item {
  cursor: pointer;
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ExpertHelpCenter--Options .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}

.upload-button {
  display: flex;
  align-items: center;
  border: 1px solid #e3e3e4;
  border-radius: 4px;
  margin-top: 15px;
}

.upload-button-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-right: 1px solid #e3e3e4;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
  background-color: #f3f3f3;
}

.upload-button-icon {
  margin-right: 4px;
}

.upload-button-input {
  display: none;
}

.selected-files {
  margin-top: 10px;
  width: 70%;
}

.file-name {
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* margin-right: 20px; */
  width: 100%;
}

.ExpertHelpCenter--Dashboard {
  background: white;
}
.ExpertHelpCenter--Dashboard .swiper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Help-center--card {
  background: #fff;
}

.Help-center--card:hover {
  background: #f6f6f6;
}

.Help-center-video-dialog .MuiPaper-root {
  min-width: 800px !important;
}

@media screen and (max-width: 1024px) {
  .Help-center-video-dialog .MuiPaper-root {
    min-width: 95vw !important;
    padding: 0px !important;
  }
  .Help-center-video-dialog .MuiDialogContent-root {
    padding: 10px !important;
  }
}
.SettingsDashboard .ExpertSettingsDashboard--Header h2 {
  color: #000;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ExpertSettingsDashboard--Options {
  justify-content: space-between;
}
.ExpertSettingsDashboard--Options .Options .Option--Item {
  padding: 11px 25px;
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ExpertSettingsDashboard--Options .Options .SelectedItem {
  background: #73589b;
  color: #fff;
}

.ExpertSettingsDashboard--Content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.ExpertSettingsDashboard--Content h4 {
  color: #000;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.ExpertSettingsDashboard--Content h6 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 20px;
}
.ExpertSettingsDashboard--Content .css-1xhypcz-MuiStack-root {
  padding-top: 0 !important;
}

.UploadedFiles--Section {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.UploadedFiles--Section .UploadedItem {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.UploadedFiles--Section .UploadedItem img {
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  padding: 2px;
  aspect-ratio: 1/1;
}
.UploadedFiles--Section .UploadedItem p {
  margin-top: 5px;
  color: #21231e;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.Content--Section > span {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.Stella--Mode__Table .heading-text {
  color: #6b7280;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.Stella--Mode__Table .link {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
}
.Stella--Mode__Table .link .text-container {
  flex: 1;
  max-width: 30ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Stella--Mode__Table .link .copy-icon {
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Stella--Mode__Table .link .copy-icon svg {
  width: 16.8px;
  height: 16.8px;
  color: #414141;
}
.Stella--Mode__Table .row-qrcode {
  display: flex;
  height: 36px;
  width: 60px;
  border-radius: 8px;
  border: 1px solid var(--Light-Grey-2, rgba(28, 27, 31, 0.12));
  background: #fff;
}
.Stella--Mode__Table .row-qrcode .qr-code-icon {
  width: 30px;
  height: 26px;
  padding: 4px;
}
.Stella--Mode__Table .row-qrcode .download-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 36px;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid #e4e4e4;
  background: #f3f3f3;
}

.Add-Stella-Mode .MuiPaper-root {
  min-width: 600px;
  max-height: 600px;
  min-height: 500px;
}

.ChatDashboard .SearchBox .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}
.ChatDashboard .SearchBox--Container {
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid lightgrey;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ChatDashboard .MuiGrid-item {
  position: relative;
}
.ChatDashboard .ClientsChatList--Container {
  max-height: calc(100vh - 202px);
  height: calc(100vh - 202px);
  overflow-y: scroll;
}
.ChatDashboard .ClientsChatList--Container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ChatDashboard .ClientsChatList--Container::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.ChatDashboard .ClientsChatList--Container > * + * {
  border-top-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item {
  cursor: pointer;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item:hover {
  background-color: rgba(115, 88, 155, 0.1411764706);
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container {
  flex: 1;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item .ClientName--Container p {
  color: #6a6c6a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ChatDashboard .ClientsChatList--Container .ClientChat--Item span {
  display: inline-flex;
  padding: 2px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 35px;
  background: #153300;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ChatDashboard .ChatSection--Container {
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
}
.ChatDashboard .ChatSection--Container .ChatSection--Header {
  padding: 11px 20px;
  border-radius: 15px 15px 0px 0px;
  position: sticky;
  top: 0;
}
.ChatDashboard .ChatSection--Container .ChatSection--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) -5%, rgba(0, 0, 0, 0) 2%), #fff;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 280px);
  height: calc(100vh - 280px);
  overflow-y: auto;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats::-webkit-scrollbar-thumb {
  background-color: #d7d7d7; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat {
  margin-top: 15px;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat .messagesArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .receivedChat .messagesArea div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  border-radius: 10px;
  background: #f5f5f5;
  margin-left: auto;
  padding: 10px;
  margin-right: auto;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat {
  flex-direction: row-reverse;
  margin-left: auto;
  margin-top: 15px;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat .messagesArea {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}
.ChatDashboard .ChatSection--Container .ChatSection--Chats .sentChat .messagesArea div {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
  border-radius: 10px;
  background: rgba(115, 88, 155, 0.0901960784);
  padding: 10px;
  margin-left: auto;
}
.ChatDashboard .ChatSection--Container .SendText--Container {
  background-color: rgba(115, 88, 155, 0.0901960784);
  z-index: 10;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 1px solid rgba(115, 88, 155, 0.1411764706);
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile {
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  gap: 10px;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div {
  display: grid;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div p {
  color: #73589b;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
}
.ChatDashboard .ChatSection--Container .SendText--Container .SelectedFile > div span {
  color: #929292;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 17.403px */
  letter-spacing: 0.18px;
}

.SessionDashboard--StatsCards {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
}
.SessionDashboard--StatsCards .StatsCard--Item {
  padding: 10px;
  background-color: rgba(115, 88, 155, 0.1);
  border-radius: 8px;
}
.SessionDashboard--StatsCards .StatsCard--Item h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings > div p {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings .Item--Tag {
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings .Expert {
  background-color: #6c46a0;
  color: #fff;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings .Client {
  background-color: #fff;
  color: #6c46a0;
  border: 0.5px solid #6c46a0;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings .OnTime {
  background-color: #55a51d;
  color: #fff;
}
.SessionDashboard--StatsCards .StatsCard--Item .Item--Timings .Late {
  background-color: #df2a2a;
  color: #fff;
}

.SessionDashboard--GraphCards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
}
.SessionDashboard--GraphCards .GraphCard--Item h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer {
  height: 50px;
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:nth-child(1) {
  background-color: rgb(108, 70, 160);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:nth-child(2) {
  background-color: rgba(108, 70, 160, 0.85);
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:nth-child(3) {
  background-color: rgba(108, 70, 160, 0.75);
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:nth-child(4) {
  background-color: rgba(108, 70, 160, 0.65);
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:nth-child(5) {
  background-color: rgba(108, 70, 160, 0.5);
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer p {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
}
.SessionDashboard--GraphCards .GraphCard--Item .Item--GraphContainer span {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.SessionDashboard--GraphCards .GraphCard--Item .Lang--Item {
  color: #fff;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SessionDashboard--GraphCards .GraphCard--Item .Lang--Item:nth-child(1) {
  background-color: #55a51d;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.SessionDashboard--GraphCards .GraphCard--Item .Lang--Item:nth-child(2) {
  background-color: #5f8cff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.SessionDashboard--GraphCards .GraphCard--Item .Lang--Item p {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
}
.SessionDashboard--GraphCards .GraphCard--Item .Lang--Item span {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.sessionManagement--floatingContainer {
  position: fixed;
  right: 0;
  top: 20vh;
  z-index: 1000;
}
.sessionManagement--floatingContainer .floatingContainer {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.sessionManagement--floatingContainer .floatingContainer .assistantIcon {
  border-radius: 100px 0px 0px 100px;
  padding: 10px;
  cursor: pointer;
}
.sessionManagement--floatingContainer .floatingContainer .assistantIcon .assistantText {
  color: var(--Text-text-on-color, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.assistant--Modal .MuiBox-root {
  width: 70vw !important;
  height: 90vh !important;
  border-radius: 10px 10px 10px 10px;
}
.assistant--Modal .header--Tab {
  border-bottom: 2px solid var(--primary-color);
  padding: 2px;
}
.assistant--Modal .header--Tab .tab1,
.assistant--Modal .header--Tab .tab2 {
  padding: 20px 35px;
  background-color: #fff;
  color: var(--Text-text-secondary, #252422);
  text-align: center;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px; /* 121.429% */
  border-radius: 10px 0px 0px 0px;
}
.assistant--Modal .header--Tab .selectedTab {
  background-color: var(--primary-color) !important;
  color: var(--Text-text-on-color, #fff);
  font-weight: 600;
}
.assistant--Modal .header--Tab .svg-shape::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-right: 85px solid white;
}
.assistant--Modal .header--Tab .svg-shape::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-left: 85px solid white;
}
.assistant--Modal .content {
  padding: 20px 0px;
  max-height: 100%;
}
.assistant--Modal .content .tabDetail-Holder {
  margin: 16px;
}
.assistant--Modal .content .tabDetail-Holder .tabDetail {
  min-width: 100px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 100px;
  padding: 8px 15px;
  white-space: nowrap;
}
.assistant--Modal .content .innerTab--section {
  height: 100%;
}
.assistant--Modal .content .innerTab--section .innerTab--container {
  overflow-y: scroll;
  height: 73vh;
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item {
  border-radius: 6px;
  border: 1px solid var(--Color-Black-50, #e9e8e7);
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item button {
  border-radius: 8px;
  background: var(--primary-accent-color);
  border: none;
  color: var(--primary-color);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 10px;
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .header {
  border-bottom: 1px solid var(--Color-Black-50, #e9e8e7);
  padding: 10px 15px;
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .header h5 {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .body {
  padding: 10px 35px;
}
.assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .body ul li {
  color: var(--Text-text-secondary, #252422);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.assistant--Modal .content .graph--container {
  position: relative;
  overflow-y: auto;
  height: 80vh !important;
}
.assistant--Modal .content .chat--container {
  padding: 20px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  overflow-y: auto;
  height: 75vh;
  margin-bottom: 20px;
}
.assistant--Modal .content .chat--container .sentChat {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: flex-start;
  gap: 5px;
}
.assistant--Modal .content .chat--container .sentChat .textContainer {
  border-radius: 12px;
  background: #f2f2f2;
}
.assistant--Modal .content .chat--container .sentChat .textContainer p {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.assistant--Modal .content .chat--container .sentChat img {
  width: 34px;
  height: 34px;
}
.assistant--Modal .content .chat--container .receivedChat {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  gap: 5px;
}
.assistant--Modal .content .chat--container .receivedChat .textContainer {
  border-radius: 12px;
  border: 1px solid var(--primary-color-opacity50);
  background: var(--primary-accent-color);
}
.assistant--Modal .content .chat--container .receivedChat .textContainer p {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.assistant--Modal .content .chat--container .receivedChat img {
  width: 34px;
  min-width: 34px !important;
  height: 34px;
  -o-object-fit: cover;
     object-fit: cover;
}
.assistant--Modal .content .chat--container .chatInputContainer {
  border-radius: 10px;
  border: 1px solid #cbcbcb;
  background: #fff;
  position: fixed;
  bottom: 10px;
  left: 20px;
  right: 20px;
  padding: 20px;
  z-index: 999;
}

@media screen and (max-width: 1024px) {
  .assistant--Modal .MuiBox-root {
    width: 95vw !important;
    height: 90vh !important;
    border-radius: 10px 10px 10px 10px;
  }
  .assistant--Modal .header--Tab {
    border-bottom: 2px solid var(--primary-color);
    padding: 2px;
  }
  .assistant--Modal .header--Tab .tab1,
  .assistant--Modal .header--Tab .tab2 {
    padding: 20px 35px;
    background-color: #fff;
    color: var(--Text-text-secondary, #252422);
    text-align: center;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 121.429% */
    border-radius: 10px 0px 0px 0px;
  }
  .assistant--Modal .header--Tab .selectedTab {
    background-color: var(--primary-color) !important;
    color: var(--Text-text-on-color, #fff);
    font-weight: 600;
  }
  .assistant--Modal .header--Tab .svg-shape::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-right: 85px solid white;
  }
  .assistant--Modal .header--Tab .svg-shape::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 85px solid white;
  }
  .assistant--Modal .content {
    padding: 20px 0px;
    max-height: 100%;
  }
  .assistant--Modal .content .tabDetail-Holder {
    margin: 16px;
  }
  .assistant--Modal .content .tabDetail-Holder .tabDetail {
    min-width: 100px;
    width: -moz-max-content;
    width: max-content;
    border-radius: 100px;
    padding: 8px 15px;
    white-space: nowrap;
  }
  .assistant--Modal .content .innerTab--section {
    height: 100%;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container {
    overflow-y: scroll;
    height: 73vh;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item {
    border-radius: 6px;
    border: 1px solid var(--Color-Black-50, #e9e8e7);
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item button {
    border-radius: 8px;
    background: var(--primary-accent-color);
    border: none;
    color: var(--primary-color);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 4px 10px;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .header {
    border-bottom: 1px solid var(--Color-Black-50, #e9e8e7);
    padding: 10px 15px;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .header h5 {
    color: var(--Text-text-primary, #080807);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .body {
    padding: 10px 35px;
  }
  .assistant--Modal .content .innerTab--section .innerTab--container .clinicalnotes--assistant__item .body ul li {
    color: var(--Text-text-secondary, #252422);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .assistant--Modal .content .graph--container {
    position: relative;
    overflow-y: auto;
    height: 80vh !important;
  }
  .assistant--Modal .content .chat--container {
    padding: 20px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    overflow-y: auto;
    height: 75vh;
    margin-bottom: 20px;
  }
  .assistant--Modal .content .chat--container .sentChat {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-start;
    gap: 5px;
  }
  .assistant--Modal .content .chat--container .sentChat .textContainer {
    border-radius: 12px;
    background: #f2f2f2;
  }
  .assistant--Modal .content .chat--container .sentChat .textContainer p {
    color: var(--Text-text-primary, #080807);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
  .assistant--Modal .content .chat--container .sentChat img {
    width: 34px;
    height: 34px;
  }
  .assistant--Modal .content .chat--container .receivedChat {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-start;
    gap: 5px;
  }
  .assistant--Modal .content .chat--container .receivedChat .textContainer {
    border-radius: 12px;
    border: 1px solid var(--primary-color-opacity50);
    background: var(--primary-accent-color);
  }
  .assistant--Modal .content .chat--container .receivedChat .textContainer p {
    color: var(--Text-text-primary, #080807);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
  }
  .assistant--Modal .content .chat--container .receivedChat img {
    width: 34px;
    min-width: 34px !important;
    height: 34px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .assistant--Modal .content .chat--container .chatInputContainer {
    border-radius: 10px;
    border: 1px solid #cbcbcb;
    background: #fff;
    position: fixed;
    bottom: 10px;
    left: 20px;
    right: 20px;
    padding: 20px;
    z-index: 999;
  }
}
.SessionDashboard--profileSummary .profileSummary-Header {
  padding: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.SessionDashboard--profileSummary .profileSummary-Header h6 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
  margin: 0;
}
.SessionDashboard--profileSummary .profileSummary-Header a {
  color: var(--primary-color);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  cursor: pointer;
}
.SessionDashboard--profileSummary .profileSummary--body {
  padding: 16px;
}
.SessionDashboard--profileSummary .profileSummary--body .leftSide p {
  color: var(--Color-Black-500, #52504c);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}
.SessionDashboard--profileSummary .profileSummary--body .leftSide p span {
  color: var(--Text-text-primary, #080807);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
}
.SessionDashboard--profileSummary .profileSummary--body .leftSide p a {
  color: var(--primary-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  cursor: pointer;
}
.SessionDashboard--profileSummary .profileSummary--body .rightSide p {
  color: var(--Color-Black-500, #52504c);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}
.SessionDashboard--profileSummary .profileSummary--body .rightSide div {
  border-radius: 10px;
  border: 1px solid var(--Color-Black-50, #e9e8e7);
  background: var(--Text-text-on-color, #fff);
  padding: 10px 20px;
  height: 160px;
  overflow-x: scroll;
}
.SessionDashboard--profileSummary .profileSummary--body .rightSide ul li {
  color: var(--Text-text-secondary, #252422);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.summary--Modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  height: 600px !important;
}
.summary--Modal .css-leqjib-MuiInputBase-root-MuiFilledInput-root {
  border-radius: 10px !important;
}
.summary--Modal .SpeechTextArea .css-leqjib-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: none !important;
}
.summary--Modal .summaryBody p {
  color: var(--Text-text-placeholder, #807c75);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}
.summary--Modal .summaryBody h6 {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  margin: 12px 0;
}
.summary--Modal .summaryBody .expertNote {
  border-radius: 6px;
  border: 1px solid var(--Color-Black-100, #d5d4d2);
  background: var(--Text-text-on-color, #fff);
  padding: 10px;
  color: var(--Text-text-secondary, #252422);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px; /* 181.25% */
}
.summary--Modal .summaryBody .medicationAccordionContainer .css-o4b71y-MuiAccordionSummary-content {
  color: var(--Text-text-secondary, #252422) !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 29px !important; /* 181.25% */
}
.summary--Modal .summaryBody .medicationAccordionContainer p {
  color: #807c75;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.summary--Modal .summaryBody .medicationAccordionContainer h6 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 20.8px */
}
.summary--Modal .summary-footer p {
  color: var(--Text-text-secondary, #252422);
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.summary--Modal .summary-footer .pagination ul button {
  border: 1px solid #e6e7eb;
  background: var(--Text-text-on-color, #fff);
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.summary--Modal .summary-footer .pagination ul .Mui-selected {
  background: #73589b;
  color: var(--Text-text-on-color, #fff);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}

.recommendedTest--Modal label {
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin: 8px 0;
}
.recommendedTest--Modal .button1 {
  border-radius: 8px;
  background: var(--Color-Black-50, #e9e8e7);
  color: var(--Text-text-secondary, #252422);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  padding: 10px 40px;
}
.recommendedTest--Modal .button2 {
  border-radius: 8px;
  background: #6c46a0;
  color: var(--Text-text-on-color, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  padding: 10px 40px;
}

.SessionDashboard--TabsContainer {
  border-bottom: 1px solid #d9d9d9;
}
.SessionDashboard--TabsContainer .MuiTabs-indicator {
  color: var(--primary-color);
  background: var(--primary-color);
}
.SessionDashboard--TabsContainer .SessionDashboard--Tabs .MuiTabs-flexContainer button {
  text-transform: capitalize;
  padding: 12px 10px !important;
  font-weight: 600 !important;
}
.SessionDashboard--TabsContainer .SessionDashboard--Tabs .Mui-selected {
  color: var(--primary-color);
}
.SessionDashboard--TabsContainer .SessionDashboard--Tabs .css-1aquho2-MuiTabs-indicator {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 4px !important;
  background-color: var(--primary-color);
}

.SessionDashboard--TabItemsContainer .SessionTab--Panel {
  padding: 20px 10px;
}

.AutoComplete--Container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 0 10px;
}
.AutoComplete--Container .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.AutoComplete--Container .MuiAutocomplete-endAdornment button {
  display: none;
}
.AutoComplete--Container > button {
  border: none;
  border-left: 1px solid #b9b4b4;
  padding: 10px;
  background-color: transparent;
}
.AutoComplete--Container svg {
  color: #6f6b6b;
}
.AutoComplete--Container .MuiFormLabel-root {
  display: none;
}

.SessionPrescription--Table,
.SessionLabAdvice--Table {
  width: 100%;
  padding: 10px;
}
.SessionPrescription--Table tr > * + *,
.SessionLabAdvice--Table tr > * + * {
  margin-left: 0.5rem; /* 8px */
}
.SessionPrescription--Table .Table--Head div,
.SessionLabAdvice--Table .Table--Head div {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
}
.SessionPrescription--Table .Table--Head,
.SessionPrescription--Table .Table--Body,
.SessionLabAdvice--Table .Table--Head,
.SessionLabAdvice--Table .Table--Body {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.SessionPrescription--Table .Table-span-1,
.SessionLabAdvice--Table .Table-span-1 {
  grid-column: span 1/span 1;
}
.SessionPrescription--Table .Table-span-2,
.SessionLabAdvice--Table .Table-span-2 {
  grid-column: span 2/span 2;
}
.SessionPrescription--Table .Table-span-3,
.SessionLabAdvice--Table .Table-span-3 {
  grid-column: span 3/span 3;
}
.SessionPrescription--Table .Table-span-4,
.SessionLabAdvice--Table .Table-span-4 {
  grid-column: span 4/span 4;
}
.SessionPrescription--Table .Table-span-5,
.SessionLabAdvice--Table .Table-span-5 {
  grid-column: span 5/span 5;
}
.SessionPrescription--Table .Table-span-6,
.SessionLabAdvice--Table .Table-span-6 {
  grid-column: span 6/span 6;
}
.SessionPrescription--Table .recommendedLabTest--Container h6,
.SessionLabAdvice--Table .recommendedLabTest--Container h6 {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  margin: 0;
}
.SessionPrescription--Table .recommendedLabTest--Container .recommendedLabTestDiv,
.SessionLabAdvice--Table .recommendedLabTest--Container .recommendedLabTestDiv {
  border-radius: 100px;
  background: rgba(108, 70, 160, 0.1);
  padding: 8px;
  color: #6c46a0;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.PrescriptionOtherData--Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}
.PrescriptionOtherData--Container .OtherData--Item > div {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  margin-top: 10px;
}
.PrescriptionOtherData--Container .OtherData--Item > div .rdw-editor-main {
  padding: 10px;
  max-height: 200px;
  min-height: 200px;
}

.generated-prescription-container {
  background: rgba(255, 255, 255, 0.29);
  border-radius: 16px;
  border: 1px solid rgb(255, 255, 255);
}
.generated-prescription-container .rightSideDiv {
  border-radius: 6px;
  background: rgba(115, 88, 155, 0.1);
  cursor: pointer;
  color: #73589b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.generated-prescription-container .prescriptionUploadDiv .browseButton {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--Text-text-on-color, #fff);
  width: -moz-fit-content;
  width: fit-content;
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.generated-prescription-container .prescriptionUploadDiv .browseButton .prescriptionUploadLeft {
  color: var(--Text-text-placeholder, #807c75);
  font-family: Roboto !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  white-space: nowrap;
}
.generated-prescription-container .prescriptionUploadDiv .browseButton .uploadBtnTextArea {
  border-radius: 0px 6px 6px 0px;
  border-left: 1px solid var(--gray-300, #d1d5db);
  background: var(--gray-50, #f9fafb);
}
.generated-prescription-container .prescriptionUploadDiv .uploadButton {
  border-radius: 6px;
  background: var(--primary-color);
  border: none;
  color: var(--Text-text-on-color, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  padding: 0 19px;
}
.generated-prescription-container .prescriptionUploadDiv .uploadedFileContainer {
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--Text-text-on-color, #fff);
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
.generated-prescription-container .prescriptionUploadDiv .uploadedFileContainer p {
  color: #0f0f0f;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.generated-prescription-container .prescriptionUploadDiv .rightSide {
  border-radius: 6px;
  background: rgba(115, 88, 155, 0.1);
  color: #73589b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SessionPrescription--box {
  box-shadow: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.session-box-blink {
  animation: blink 3s infinite ease-in;
}

@keyframes blink {
  0% {
    box-shadow: 0px 4px 16px -5px rgb(107, 70, 160);
  }
  50% {
    box-shadow: 0px 4px 16px -5px rgb(180, 138, 240);
  }
  100% {
    box-shadow: 0px 4px 16px -5px rgb(107, 70, 160);
  }
}
.recorder {
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.session-stat-OnTime {
  color: #55a51d;
  background-color: rgba(85, 165, 29, 0.1019607843);
}

.session-stat-Late {
  color: #df2a2a;
  background-color: rgba(223, 42, 42, 0.1019607843);
}

.clinicalDashboard--scroll ::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}
.clinicalDashboard--scroll ::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Color of the scrollbar handle */
  border-radius: 6px; /* Border radius of the scrollbar handle */
}

:root {
  --hue: 258; /* Converted HSL hue for #73589B */
  --bg: hsl(var(--hue), 80%, 90%);
  --fg: hsl(var(--hue), 10%, 50%);
  --primary: hsl(var(--hue), 90%, 55%);
  --primary-l: hsl(var(--hue), 90%, 65%);
  --primary-d: hsl(var(--hue), 90%, 45%);
  --white: hsl(var(--hue), 80%, 95%);
  --white-d: hsl(var(--hue), 80%, 80%);
}

/* Rest of the CSS remains unchanged */
.book,
.book__pg-shadow,
.book__pg {
  animation: cover 7s ease-in-out infinite;
}

.book {
  background-color: var(--primary-l);
  z-index: 100000;
  border-radius: 0.25em;
  /* box-shadow:
    0 0.25em 0.5em hsla(0,0%,0%,0.3),
    0 0 0 0.25em var(--primary) inset; */
  padding: 0.25em;
  perspective: 37.5em;
  width: 8em;
  height: 6em;
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
}

.book__pg-shadow,
.book__pg {
  position: absolute;
  left: 0.25em;
  width: calc(50% - 0.25em);
}

.book__pg-shadow {
  animation-name: shadow;
  background-image: linear-gradient(-45deg, hsla(0, 0%, 0%, 0) 50%, hsla(0, 0%, 0%, 0.3) 50%);
  filter: blur(0.25em);
  top: calc(100% - 0.25em);
  height: 3.75em;
  transform: scaleY(0);
  transform-origin: 100% 0%;
}

.book__pg {
  animation-name: pg1;
  background-color: var(--white);
  background-image: linear-gradient(90deg, hsla(var(--hue), 10%, 90%, 0) 87.5%, hsl(var(--hue), 10%, 90%));
  height: calc(100% - 0.5em);
  transform-origin: 100% 50%;
}

.book__pg--2,
.book__pg--3,
.book__pg--4 {
  background-image: repeating-linear-gradient(hsl(var(--hue), 10%, 10%) 0 0.125em, hsla(var(--hue), 10%, 10%, 0) 0.125em 0.5em), linear-gradient(90deg, hsla(var(--hue), 10%, 90%, 0) 87.5%, hsl(var(--hue), 10%, 90%));
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2.5em 4.125em, 100% 100%;
}

.book__pg--2 {
  animation-name: pg2;
}

.book__pg--3 {
  animation-name: pg3;
}

.book__pg--4 {
  animation-name: pg4;
}

.book__pg--5 {
  animation-name: pg5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 10%, 30%);
    --fg: hsl(var(--hue), 10%, 90%);
  }
}
/* Animations */
@keyframes cover {
  from, 5%, 45%, 55%, 95%, to {
    animation-timing-function: ease-out;
    background-color: var(--primary-l);
  }
  10%, 40%, 60%, 90% {
    animation-timing-function: ease-in;
    background-color: var(--primary-d);
  }
}
@keyframes shadow {
  from, 10.01%, 20.01%, 30.01%, 40.01% {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0.2) rotateY(90deg);
  }
  10%, 20%, 30%, 40%, 50%, to {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
  }
  50.01%, 60.01%, 70.01%, 80.01%, 90.01% {
    animation-timing-function: ease-in;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
  }
  60%, 70%, 80%, 90%, to {
    animation-timing-function: ease-out;
    transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
  }
}
@keyframes pg1 {
  from, to {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.4deg);
  }
  10%, 15% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
  20%, 80% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
  85%, 90% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(180deg);
  }
}
@keyframes pg2 {
  from, to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
  5%, 10% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
  20%, 25% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  30%, 70% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  75%, 80% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.9deg);
  }
  90%, 95% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.3deg);
  }
}
@keyframes pg3 {
  from, 10%, 90%, to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
  15%, 20% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
  30%, 35% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  40%, 60% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  65%, 70% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.8deg);
  }
  80%, 85% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.2deg);
  }
}
@keyframes pg4 {
  from, 20%, 80%, to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
  25%, 30% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
  40%, 45% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  50% {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  55%, 60% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.7deg);
  }
  70%, 75% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0.1deg);
  }
}
@keyframes pg5 {
  from, 30%, 70%, to {
    animation-timing-function: ease-in;
    background-color: var(--white-d);
    transform: translate3d(0, 0, 1px) rotateY(0);
  }
  35%, 40% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0deg);
  }
  50% {
    animation-timing-function: ease-in-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(179.6deg);
  }
  60%, 65% {
    animation-timing-function: ease-out;
    background-color: var(--white);
    transform: translate3d(0, 0, 1px) rotateY(0);
  }
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: -moz-fit-content;
  height: fit-content;
  overflow-wrap: break-word;
}

.sc-eqUAAy .file-types {
  display: none;
}

.Notetaker--FileContainer span {
  font-size: large !important;
}

.assistant-message-response {
  background-color: rgb(209, 209, 209);
  width: -moz-max-content;
  width: max-content;
}
.assistant-message-response .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 9px;
  margin: -13px auto;
  text-align: center;
}
.assistant-message-response .spinner div {
  width: 9px;
  height: 9px;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out;
}
.assistant-message-response .spinner div:nth-child(1) {
  animation-delay: -0.32s;
}
.assistant-message-response .spinner div:nth-child(2) {
  animation-delay: -0.16s;
}
.assistant-message-response #loading-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 37.6px;
  min-width: 73px;
  border-radius: 10px;
  position: relative;
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.date--picker .MuiInputBase-input {
  padding: 8.5px 32px 8.5px 14px;
}

.SessionTranscript--ScrollBar {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.SessionTranscript--ScrollBar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}
.SessionTranscript--ScrollBar::-webkit-scrollbar-thumb {
  background-color: #e1e1e1; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

.clinicalNote--ClientItem {
  border: 1px solid #eeeeee;
  border-radius: 12px;
  background: #fff;
}
.clinicalNote--ClientItem .ClientItem--Header img {
  border-radius: 50%;
}
.clinicalNote--ClientItem .ClientItem--Header h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 0 !important;
}
.clinicalNote--ClientItem .ClientItem--Header p {
  color: #6a6c6a;
  font-size: 16px;
}

.clinicalNote--Grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  margin: 20px 0;
  width: 100%;
  padding: 25px;
}

.client-details-dialog .MuiDialog-paper {
  min-width: 600px !important;
  max-width: 600px !important;
  max-height: 600px !important;
}

.create-clinic-modal .MuiBox-root {
  min-width: 600px !important;
  max-width: 600px !important;
  max-height: 600px !important;
}

.session-details-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 80vw !important;
}

.notetaker-new-dialog .MuiDialog-paper {
  min-width: 60vw !important;
  height: 80vh !important;
  border-radius: 10px 10px 10px 10px;
}

@media screen and (max-width: 1024px) {
  .client-details-dialog .MuiDialog-paper {
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .create-clinic-modal .MuiBox-root {
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .notetaker-new-dialog .MuiDialog-paper {
    min-width: 95% !important;
    max-width: 95% !important;
  }
}
.add-client-details .MuiOutlinedInput-input {
  padding: 5px 8px 5px 8px;
  background-color: white;
  margin-top: 5px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
  font-size: 16px !important;
}
.add-client-details .MuiOutlinedInput-notchedOutline {
  border-radius: 0px !important;
  border: 0;
}
.add-client-details .datePicker {
  margin-top: 5px;
  overflow: hidden;
}
.add-client-details .datePicker .MuiStack-root {
  overflow: hidden;
}
.add-client-details .datePicker .MuiOutlinedInput-input {
  padding: 5px 8px 5px 8px;
  background-color: white;
  margin-top: 0px;
  border: 0;
  font-size: 16px !important;
}
.add-client-details .css-134uato-MuiFormControl-root-MuiTextField-root {
  background: white;
  border-radius: 6px;
  border: 1px solid #d1d5db;
}

.recommendation-assessment .MuiPaper-root {
  min-width: 800px !important;
  height: 750px;
}

.disclaimer-modal .MuiPaper-root {
  min-width: 1000px !important;
}

@media screen and (max-width: 1024px) {
  .recommendation-assessment .MuiPaper-root {
    min-width: 95% !important;
    height: 600px;
  }
  .disclaimer-modal .MuiPaper-root {
    min-width: 95% !important;
    height: 90vh;
  }
}
.clinicalnotes-submit-dialog .MuiPaper-root {
  width: 377px !important;
  border-radius: 10px;
}
.clinicalnotes-submit-dialog .save {
  border-radius: 10px !important;
  border: none;
  background-color: #ffffff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #344054 !important;
  text-transform: capitalize !important;
  font-size: 16px;
  border: solid 1px #d0d5dd;
}
.clinicalnotes-submit-dialog .save:hover {
  background-color: #f2f2f2 !important;
}
.clinicalnotes-submit-dialog .submit {
  border-radius: 10px !important;
  border: none;
  background-color: #079455 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px;
}
.clinicalnotes-submit-dialog .submit:hover {
  background-color: #067c47 !important;
}
.clinicalnotes-submit-dialog .delete {
  border-radius: 10px !important;
  border: none;
  background-color: #d92d20 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px;
}
.clinicalnotes-submit-dialog .delete:hover {
  background-color: #c3281d !important;
}
.clinicalnotes-submit-dialog .archive {
  border-radius: 10px !important;
  border: none;
  background-color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px;
}
.clinicalnotes-submit-dialog .archive:hover {
  background-color: var(--primary-color-darken) !important;
}

.clinicalnotes-submit-save .save {
  border-radius: 10px !important;
  border: none;
  background: white !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #344054 !important;
  text-transform: capitalize !important;
  font-size: 16px;
  border: solid 1px var(--primary-color);
}
.clinicalnotes-submit-save .save:hover {
  background-color: #f2f2f2 !important;
}
.clinicalnotes-submit-save .submit {
  border-radius: 10px !important;
  border: none;
  background: #079455 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px;
}
.clinicalnotes-submit-save .submit:hover {
  background-color: #067c47 !important;
}

.create-note-button {
  position: relative !important;
}

@media screen and (max-width: 1024px) {
  .create-note-button {
    position: fixed !important;
    bottom: 30px;
    right: 10px;
  }
}
.recommender-history-dialog .MuiDialog-paper {
  min-height: 400px !important;
  min-width: 800px !important;
  max-width: 1200px !important;
}
.recommender-history-dialog .MuiAccordion-root {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

@media screen and (max-width: 1024px) {
  .recommender-history-dialog .MuiDialog-paper {
    min-height: 600px !important;
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .recommender-history-dialog .MuiAccordion-root {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .create-client-dialog .MuiDialog-paper {
    min-height: 500px !important;
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .create-client-dialog .MuiAccordion-root {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
}
.Pagination ul button {
  border: 1px solid #e6e7eb;
  background: var(--Text-text-on-color, #fff);
  color: var(--Text-text-primary, #080807);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}
.Pagination ul .Mui-selected {
  background: #73589b;
  color: var(--Text-text-on-color, #fff);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 20.8px */
}

.Table-Footer-p {
  color: var(--Text-text-secondary, #252422);
  font-family: Inter !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*Cards*/
.UpcomingAppointmentCard {
  width: 100%;
  justify-content: space-between;
}
.UpcomingAppointmentCard .name {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: -10px;
  margin-right: -10px;
}
.UpcomingAppointmentCard > div {
  width: 100%;
  flex: 1;
}
.UpcomingAppointmentCard > p {
  font-size: 14px !important;
}
.UpcomingAppointmentCard .AppointmentType {
  font-size: 13px !important;
  color: #6a6c6a;
  line-height: 130%;
}

.upcomingAppointment > div,
.default > div {
  border: 1px solid;
}

.upcomingAppointment > div {
  border-color: #f3f3f3;
}
.upcomingAppointment > p {
  color: #6A6C6A;
}
.upcomingAppointment:hover > div {
  border-color: rgba(115, 88, 155, 0.4588235294);
  outline-style: solid !important;
  outline-width: 5px !important;
  outline-color: rgba(115, 88, 155, 0.1882352941) !important;
  transition: all ease-in-out 0.2s;
}
.upcomingAppointment:hover > p {
  color: #73589b;
  transition: all ease-in-out 0.2s;
}

.NotificationRowCard {
  padding: 20px;
  display: flex;
  align-items: start;
  gap: 15px;
}
.NotificationRowCard img {
  width: 40px;
  min-width: 40px;
}
.NotificationRowCard .newAppointmentIcon {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
}
.NotificationRowCard .newAppointmentIcon svg {
  width: 21px;
  height: 21px;
  color: #153300;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.NotificationRowCard .NotificationRowCard--Content .header {
  color: #0e0f0c;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
}
.NotificationRowCard .NotificationRowCard--Content .review {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions button {
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions .ProfileCTA {
  background-color: #f6fdf0;
  color: #153300;
  text-transform: capitalize;
}
.NotificationRowCard .NotificationRowCard--Content .Content--Actions .JoinCTA {
  background-color: #73589b;
  color: #fff;
}
.NotificationRowCard .newAppointment--details {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.NotificationRowCard .newAppointment--details p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.InboxWidget--Item > div {
  width: 100%;
}
.InboxWidget--Item .Item--Contents {
  flex: 1;
}
.InboxWidget--Item .Item--Contents .Contents--Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.InboxWidget--Item .Item--Contents .Contents--Header h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.InboxWidget--Item .Item--Contents .Contents--Header .Item--UnreadMessages {
  padding: 2px 10px;
  border-radius: 5px;
  background: #153300;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.InboxWidget--Item .Item--Contents a {
  text-decoration: none !important;
  gap: 8px;
  margin-top: 5px;
}
.InboxWidget--Item .Item--Contents a p {
  color: #73589b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.InboxWidget--Item .Item--Subject {
  margin-top: 5px;
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 90%;
}

.AppointmentCard .MuiCardContent-root .Card--Top {
  align-items: center;
}
.AppointmentCard .MuiCardContent-root .Card--Top img {
  aspect-ratio: 1/1;
  height: 45px;
  width: 45px;
}
.AppointmentCard .MuiCardContent-root .Card--Top h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCard .MuiCardContent-root .Card--Top p {
  color: #6a6c6a;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.AppointmentCard .MuiCardContent-root .Card--Top svg {
  width: 18px;
  height: 18px;
  fill: #d2293d;
  margin-left: auto;
  margin-bottom: auto;
}
.AppointmentCard .MuiCardContent-root .Card--Timings {
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div svg {
  width: 21px;
  height: 21px;
  fill: #73589b;
}
.AppointmentCard .MuiCardContent-root .Card--Timings div p {
  color: #73589b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.AppointmentCard .Card--Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AppointmentCard .Card--Actions button {
  background: #f5f5f5;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
}
.AppointmentCard .Card--Actions button svg {
  width: 16px;
  height: 16px;
}
.AppointmentCard .Card--Actions button p {
  line-height: 130%;
  text-transform: capitalize;
}
.AppointmentCard .Card--Actions button:nth-child(3) {
  padding: 8px;
  min-width: 0;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.AppointmentCard .Card--Actions button:nth-child(3) svg {
  width: 20px;
  height: 20px;
}

.Appointment--Cancelled {
  background-color: #fceeee !important;
  height: 100%;
}
.Appointment--Cancelled .Card--Actions button {
  background-color: #FFF;
}

.WorksheetCard .ViewAll--Button {
  width: 100%;
  background-color: rgba(115, 88, 155, 0.4705882353);
  text-transform: capitalize;
  color: #000;
}
.WorksheetCard .ViewAll--Button:hover {
  background-color: #73589b;
  color: #FFF;
}
.WorksheetCard .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}
.WorksheetCard .MuiCardContent-root .css-anmq6p-MuiTypography-root {
  margin-bottom: 0 !important;
}
.WorksheetCard .MuiCardContent-root .MuiTypography-b {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.WorksheetCard .MuiCardContent-root .MuiTypography-span {
  color: #656565;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.WorksheetCard .MuiCardContent-root .MuiAvatarGroup-root {
  margin-top: 20px;
}

.ArticleCard .MuiCardContent-root .MuiTypography-span {
  color: #000;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
  margin-bottom: 10px;
}
.ArticleCard .MuiCardContent-root .MuiTypography-h6 {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments {
  margin-top: 12px;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments svg {
  color: #73589b;
  width: 15px;
  height: 15px;
}
.ArticleCard .MuiCardContent-root .ArticleCard--ViewsNComments span {
  color: #6a6c6a;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.21px;
}
.ArticleCard .MuiCardActions-root {
  padding-bottom: 24px;
}

.PayoutWidget {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.PayoutWidget .PayoutWidgetCard--Header {
  display: flex;
  align-items: center;
  gap: 16px;
}
.PayoutWidget .PayoutWidgetCard--Header svg {
  width: 35px;
  height: 35px;
  fill: #6A6C6A;
}
.PayoutWidget .PayoutWidgetCard--Header span {
  color: #6a6c6a;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.PayoutWidget h6 {
  color: #000;
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.ClientDetails--TableRow {
  position: relative;
  height: 68.8px;
  position: relative;
  height: 68.8px;
}
.ClientDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.ClientDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.ClientDetails--TableRow .Table--Name {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: 0.56px;
}
.ClientDetails--TableRow .Table--Name span {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ClientDetails--TableRow .Table--Name p,
.ClientDetails--TableRow .Table--Sessions p,
.ClientDetails--TableRow .Table--Worksheets p,
.ClientDetails--TableRow .Table--SessionNotes p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ClientDetails--TableRow .Table--Worksheets span,
.ClientDetails--TableRow .Table--SessionNotes span {
  color: #73589b;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ClientDetails--TableRow .Table--SessionNotes p {
  font-size: 16px;
  font-weight: 600;
}
.ClientDetails--TableRow .Table--Screener svg {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
}
.ClientDetails--TableRow .Table--Actions {
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.ClientDetails--TableRow .Table--Actions button {
  background: rgba(115, 88, 155, 0.1019607843);
  padding: 8px 15px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
  max-width: -moz-max-content;
  max-width: max-content;
}
.ClientDetails--TableRow .Table--Actions button svg {
  width: 16px;
  height: 16px;
}
.ClientDetails--TableRow .Table--Actions button p {
  line-height: 130%;
  text-transform: capitalize;
}
.ClientDetails--TableRow .Table--Actions button:last-child {
  padding: 8px;
  min-width: 36px;
  width: 36px;
  max-width: 36px;
  aspect-ratio: 1/1;
  flex: 0.25;
}
.ClientDetails--TableRow .Table--Actions button:last-child svg {
  width: 20px;
  height: 20px;
}
.ClientDetails--TableRow .Table--Actions__Alt button {
  background: var(--primary-accent-color);
  padding: 8px 15px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  color: #414141;
  font-size: 14px;
  flex: 1;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-left: auto;
}
.ClientDetails--TableRow .Table--Actions__Alt button svg {
  width: 16px;
  height: 16px;
}
.ClientDetails--TableRow .Table--Actions__Alt button p {
  line-height: 130%;
  text-transform: capitalize;
}
.ClientDetails--TableRow tbody tr td:last-child {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
}

.SelectedTableRow::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 62px;
}

.SessionNoteItem {
  background-color: #f5f5f5;
  justify-content: space-between;
  border-radius: 10px;
}
.SessionNoteItem h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0 !important;
}
.SessionNoteItem > span {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%; /* 20.303px */
  letter-spacing: 0.21px;
}
.SessionNoteItem .Actions div {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 32px;
  background: rgba(27, 139, 207, 0.1);
  padding: 7px 13px;
  font-size: 14px;
  cursor: pointer;
}
.SessionNoteItem .Actions div:hover {
  background: rgba(27, 139, 207, 0.25);
}

.TicketDetails--TableRow {
  position: relative;
  height: 68.8px;
}
.TicketDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.TicketDetails--TableRow .Table--SRNO p {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TicketDetails--TableRow .Table--Topic div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.TicketDetails--TableRow .Table--Topic div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}
.TicketDetails--TableRow .Table--Topic div span {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TicketDetails--TableRow .Table--Detail p {
  color: #656565;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.TicketDetails--TableRow .Ticket--Resolved {
  display: inline-flex;
  height: 30px;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: #fff;
}
.TicketDetails--TableRow .Ticket--Resolved__Oultine {
  display: inline-flex;
  height: 30px;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
}
.TicketDetails--TableRow .Ticket--Reply {
  display: inline-flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(157, 234, 102, 0.2);
}

.ReplyTicket--Dialog .MuiPaper-root {
  max-width: 1080px !important;
  width: 95% !important;
  max-height: 700px !important;
  height: 95% !important;
}
.ReplyTicket--Dialog .MuiDialogTitle-root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #73589b;
}
.ReplyTicket--Dialog .MuiDialogTitle-root button {
  min-width: auto;
}
.ReplyTicket--Dialog .MuiDialogTitle-root button svg {
  fill: #fff;
  width: 28px;
  height: 28px;
}
.ReplyTicket--Dialog .MuiDialogContent-root {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 10px 0;
  background: linear-gradient(90deg, rgb(250, 233, 235) 0%, rgb(255, 255, 255) 100%);
  padding: 10px 24px;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Header p {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Header span {
  color: #656565;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 24px 0px 24px;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .sentChat,
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .receivedChat {
  align-items: flex-end !important;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .sentChat .messagesArea,
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .receivedChat .messagesArea {
  display: flex;
  align-items: start;
  gap: 10px;
  width: 100%;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .sentChat .messagesArea div,
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .receivedChat .messagesArea div {
  margin-top: 5px;
  border-radius: 10px;
  flex: 1;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .receivedChat .messagesArea div {
  color: #2e2e2e;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .sentChat {
  flex-direction: row;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Chats .sentChat .messagesArea div {
  color: #2e2e2e;
  text-align: left;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send {
  padding: 10px 24px;
  background-color: #fff;
  position: sticky;
  bottom: -5px;
  margin-top: auto;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border-right: 0;
  background: #f3f3f3;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area .MuiInputBase-root {
  background: transparent !important;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area .MuiInputBase-root::after {
  border-bottom: 0 !important;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area .MuiInputBase-root::before {
  border-bottom: 0 !important;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area .TextBox {
  flex: 1;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area .TextBox input {
  height: 51px;
  padding: 0 15px;
}
.ReplyTicket--Dialog .MuiDialogContent-root .HelpChat--Send .Input--Area button {
  display: flex;
  width: 100px;
  height: 51px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px;
  background: var(--primary-color);
  color: #fff;
}

.PayoutDetails--TableRow {
  height: 68.8px;
}
.PayoutDetails--TableRow:first-child {
  border-top-left-radius: 10px;
}
.PayoutDetails--TableRow .Table--ID p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Amount > div,
.PayoutDetails--TableRow .Table--DateTime > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.PayoutDetails--TableRow .Table--Amount .debit,
.PayoutDetails--TableRow .Table--DateTime .debit {
  color: #d2293d;
}
.PayoutDetails--TableRow .Table--Amount div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Amount div span {
  color: #000;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--DateTime div p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--DateTime div span {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PayoutDetails--TableRow .Table--Remarks p {
  color: #000;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Reschedule--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Reschedule--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Reschedule--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Reschedule--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Reschedule--Dialog h4 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: 12px;
}
.Reschedule--Dialog .SlotTiming {
  margin-top: 20px;
  align-items: center;
}
.Reschedule--Dialog .SlotTiming p {
  color: #929292;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Reschedule--Dialog .SlotTiming .SelectedSlot {
  font-weight: 600;
  color: #73589b;
}
.Reschedule--Dialog .Timings {
  margin-top: 20px;
}
.Reschedule--Dialog .Timings .MuiGrid-item {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
}
.Reschedule--Dialog .Timings .MuiGrid-item p {
  color: #929292;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.Reschedule--Dialog .Timings .AvailableSlot {
  cursor: pointer;
  background-color: #fff;
}
.Reschedule--Dialog .Timings .AvailableSlot p {
  color: #000;
}
.Reschedule--Dialog .Timings .SelectedSlot {
  border: 1px solid #73589b;
  outline-width: 4px !important;
  outline-style: solid !important;
  outline-color: rgba(115, 88, 155, 0.2) !important;
}
.Reschedule--Dialog .Timings .SelectedSlot p {
  color: #73589b;
  font-weight: 600;
}

.Notes--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Notes--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Notes--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Notes--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.Prescription--Dialog .Content--Header {
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Prescription--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Prescription--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Prescription--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.audit-drawer {
  width: 60vw;
}

.letter-template {
  height: 100vh;
}

@media screen and (max-width: 1024px) {
  .audit-drawer {
    width: 100vw;
  }
}
.Cancel--Dialog .Dialog--Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  gap: 10px;
}
.Cancel--Dialog .Dialog--Content svg {
  width: 48px;
  height: 48px;
  color: #dd263c;
}
.Cancel--Dialog .Content--Header {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  padding: 15px;
}
.Cancel--Dialog .Content--Header img {
  width: 45px;
  height: 45px;
}
.Cancel--Dialog .Content--Header h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Cancel--Dialog .Content--Header span {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}

.AddApointment--Dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 100%;
}
.AddApointment--Dialog .AvailableSlot {
  cursor: pointer;
}

.PrimaryCTA {
  border-radius: 10px !important;
  border: none;
  background-color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
}
.PrimaryCTA:hover {
  background-color: var(--primary-color-darken) !important;
}

.PrimaryCTA---ALT {
  border-radius: 10px !important;
  border: none;
  background: var(--primary-accent-color);
  color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-transform: capitalize !important;
}
.PrimaryCTA---ALT:hover {
  background-color: var(--primary-color-opacity50) !important;
}

.PrimaryCTA.Mui-disabled {
  background-color: var(--primary-color-opacity50) !important;
}

.PrimaryCTA:disabled {
  background-color: var(--primary-color-opacity50) !important;
}

.PrimaryCTA---ALT {
  border-radius: 10px !important;
  border: none;
  background: var(--primary-accent-color);
  color: var(--primary-color) !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  text-transform: capitalize !important;
}
.PrimaryCTA---ALT:hover {
  background-color: var(--primary-color-opacity50) !important;
}

.PrimaryCTA--Outlined {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  text-transform: capitalize !important;
}

.PrimaryCTA--Outlined-success {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #17b26a !important;
  border: 1px solid #17b26a !important;
  text-transform: capitalize !important;
}

.PrimaryCTA--Outlined.Mui-disabled {
  background-color: rgba(251, 251, 251, 0.5607843137) !important;
}

.PrimaryCTA--Ghost {
  border-radius: 10px !important;
  background-color: #fff !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: var(--primary-color) !important;
  text-transform: capitalize !important;
}

.PrimaryCTA--Ghost.Mui-disabled {
  background-color: rgba(251, 251, 251, 0.5607843137) !important;
}

.GreenCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background-color: #9dea66 !important;
  color: #21231e !important;
  text-transform: capitalize !important;
}

.GreenCTA.Mui-disabled {
  background-color: rgba(157, 234, 102, 0.26) !important;
  color: #666666 !important;
}

.RedCTA {
  display: flex;
  height: 50px !important;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #dd263c !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.DarkCTA {
  border-radius: 10px !important;
  background-color: #111 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff !important;
  text-transform: capitalize !important;
}
.DarkCTA:hover {
  background-color: #040404 !important;
}

.DarkCTA--Outlined {
  border-radius: 10px !important;
  background-color: #fff !important;
  border: 1px solid #111 !important;
  display: flex !important;
  height: 50px;
  padding: 14px 24px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #111 !important;
  text-transform: capitalize !important;
}

.bg-white {
  background-color: #FFF !important;
}

.CheckedIcon {
  color: #73589b;
  border: 2px solid #73589b;
  border-radius: 5px;
  width: 27px;
  aspect-ratio: 1/1;
  margin-left: auto;
  margin-right: auto;
}

.DarkDropdown .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  border-radius: 10px !important;
}
.DarkDropdown .MuiMenu-list {
  background: #000;
  border-radius: 10px;
}
.DarkDropdown li {
  color: #FFF;
}

.Dialog .MuiDialog-paper {
  width: 100%;
}

.ComingSoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 540px;
}
.ComingSoon h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 18px;
  margin-bottom: 15px;
}
.ComingSoon p {
  color: #000;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.PrimaryBadge {
  color: #6f4e9f;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #e2dde8;
  border: 1px solid #c5b9d6;
  font-weight: 500;
}

.NoDataAvailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  gap: 10px;
  margin: 0 auto;
}
.NoDataAvailable img {
  width: 250px;
}
.NoDataAvailable svg {
  width: 350px;
}
.NoDataAvailable p {
  font-size: 20px;
  font-weight: 500;
}

.Notepad--Dialog .MuiPaper-root {
  border-radius: 20px !important;
  height: 666px;
  max-height: 90vh;
  width: 80vw;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-pulse {
  animation: ping 1s infinite;
}
@keyframes ping {
  0% {
    opacity: 1;
  }
  25%, 100% {
    opacity: 0;
  }
}

* {
  font-family: "Roboto", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8; /* Color of the scrollbar handle */
  border-radius: 4px; /* Border radius of the scrollbar handle */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

:root {
  --primary-color: #db5439;
  --primary-color-darken: #a33c28;
  --primary-accent-color: #db543910;
  --primary-color-opacity30: #db543930;
  --primary-color-opacity50: #db543950;
  --secondary-color: #b9452d;
  --button-primary-color: #73589b;
  --primary-gradient: linear-gradient(93deg, #05f18f -3.22%, #078a54 103.49%);
  --light-blue: #eeebf3;
}

.primary-color {
  background: var(--primary-color);
}

.btn-primary {
  background-color: var(--primary-color);
}

.btn-primary-color {
  background: var(--primary-color);
}

*::-moz-selection {
  background-color: var(--primary-color-opacity50);
}

*::selection {
  background-color: var(--primary-color-opacity50);
}

.MuiCheckbox-root {
  color: var(--primary-color) !important;
}

.modal-content {
  background-color: inherit;
}

.courseStats--Container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  width: 100%;
}
.courseStats--Container .courseStat--Item {
  grid-column: span 6/span 6;
}
@media screen and (min-width: 500px) {
  .courseStats--Container .courseStat--Item {
    grid-column: span 3/span 3;
  }
}

.dashboardCourses--Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
}
.dashboardCourses--Container .dashboardCourse--Item {
  width: 100%;
}

.AssessmentCard--title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--primary-color);
}

.AssessmentCard--author {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.AssessmentList--Container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .AssessmentList--Container {
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
  }
}

.AssessmentCard--question,
.AssessmentCard--author {
  color: #666;
  font-size: 14px;
}

.AssessmentCard--Button {
  background-color: var(--button-primary-color);
}
.AssessmentCard--Button:hover {
  background-color: #604981;
}

.pagination__link:hover {
  color: #73589b !important;
}

.btn-outline-primary {
  color: #73589b !important;
  border-color: #73589b !important;
}
.btn-outline-primary:hover {
  background-color: #73589b !important;
  color: #fff !important;
}

.btn-outline-primary.activeprimary {
  color: #fff !important;
}

.btn-success {
  background-color: #6ad167 !important;
  border: none !important;
}

.btn-danger {
  background-color: #f9606f !important;
  border: none !important;
}

@keyframes rotateFlower {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flower--container {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.flower--container svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.flower {
  position: relative;
  animation: rotateFlower 5s linear infinite;
  transform-origin: center;
}

.flower__head {
  width: 80px;
  height: 32px;
  border-radius: 20px;
  background: #dfe3ff;
  margin-top: 50px;
}

.flower__head::after,
.flower__head::before {
  content: "";
  position: absolute;
  background: #dfe3ff;
  border-radius: 20px;
  width: 80px;
  height: 32px;
  transform: rotate(60deg);
}

.flower__head::before {
  transform: rotate(-60deg);
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.missing-placeholder {
  color: red;
  font-weight: bold;
}

.Copilot--Mobile {
  display: none;
}

@media (max-width: 1024px) {
  .Copilot--Mobile {
    background-color: #fff;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    text-align: center;
  }
  .Copilot--Mobile b {
    font-size: 1.5rem;
  }
}
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
@page {
  size: auto;
  margin-top: 15mm;
  margin-bottom: 15mm;
}/*# sourceMappingURL=index.css.map */